import React from 'react'
import { useLoginRequired } from 'hooks/useLoginRequired'

import Loading from 'components/Loading'

const PrivateRoute = ({ children }) => {
  const data = useLoginRequired()
  if(data.loading) return (<Loading />)
  if(!children) return null

  return <>{children}</>
}

export default PrivateRoute

