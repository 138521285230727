import { getCredentials } from 'utils/credentials'
import { account, gats } from '../../config'

const axiosAccountConfig = {
  baseURL: account.api,
  headers: {
    Authorization : `Bearer ${getCredentials().accessToken}`,
    'content-type': 'application/json'
  }
}

const axiosGatsConfig = {
  baseURL: gats.api,
  headers: {
    Authorization : `Bearer ${getCredentials().accessToken}`,
    'content-type': 'application/json'
  }
}

export {
  axiosAccountConfig,
  axiosGatsConfig
}

