import { useEffect, useState } from 'react'
import { verifyAuth } from '../hoc/LoginRequired'

export const useLoginRequired = () => {
  const [ loading, setLoading ] = useState(true)
  useEffect(() => {
    const main = async () => {
      try {
        await verifyAuth()
      } catch (error) {
      } finally {
        setLoading(false)
      }
    }
    main()
  }, [])

  return {
    loading
  }
}

