import CHECK_FILE_SIZE from './default.gql'

import UPDATE_VOD_BY_ID from './vod/update-vod.gql'
import CREATE_VOD from './vod/create-vod.gql'
import SEND_VOD from './vod/send-vod.gql'
import SANITIZE from './vod/sanitize.gql'
import DELETE_VOD_QUESTION from './vod/delete-vod-question.gql'
import ADD_SOURCE_QUESTION from './vod/add-source-question.gql'
import UPDATE_INTRODUCTION_STATUS from './vod/updateIntroductionStatus.gql'

import ADD_CHATBOTMESSAGES_TO_VOD from './vod-reminder/add-chatbotmessages-to-vod.gql'
import CREATE_OR_UPDATE_CHATBOT_REMINDERS from './vod-reminder/create-or-update-chatbot-reminders.gql'
import SEND_INITIAL_REMINDER from './vod-reminder/send-initial-reminder.gql'
import VALID_AND_CREATE_NEW_RESULT_TASK from './resultTask/validAndCreateNewResultTask.gql'

export {
  UPDATE_VOD_BY_ID,
  CHECK_FILE_SIZE,
  CREATE_VOD,
  SEND_VOD,
  SANITIZE,
  DELETE_VOD_QUESTION,
  ADD_SOURCE_QUESTION,
  UPDATE_INTRODUCTION_STATUS,
  ADD_CHATBOTMESSAGES_TO_VOD,
  CREATE_OR_UPDATE_CHATBOT_REMINDERS,
  SEND_INITIAL_REMINDER,
  VALID_AND_CREATE_NEW_RESULT_TASK
}
