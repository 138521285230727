import urlParser from 'url-parse'
import React, { useEffect } from 'react'
import queryString from 'query-string'
import { useHistory } from 'react-router'
import postMessageController, { ProblemTypeEnum } from '../../utils/iframe'
import { LOCAL_STORAGE_KEYS, ServiceApp } from 'utils/constants'

// import { base } from '../../config'

const CallbackAuth = () => {
  const history = useHistory()

  useEffect(() => {
    const params = queryString.parse(window.location.search)
    try {
      localStorage.setItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN, params.accessToken)
      localStorage.setItem(LOCAL_STORAGE_KEYS.USER_ID, params.iduser)
      localStorage.setItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN, params.refreshToken)

      if(params && params.serviceApp && params.serviceApp === ServiceApp.Applying)
        return window.location.href = params.urlRedirect

      const parseUrlRedirect = urlParser(params.urlRedirect)

      if(parseUrlRedirect.origin === window.location.origin)
        history.replace({
          pathname: parseUrlRedirect.pathname,
          search  : parseUrlRedirect.search
        })
      else
        window.location.href = params.urlRedirect
    } catch (error) {
      return postMessageController.onProblems({
        hasProblems: true,
        problemType: ProblemTypeEnum.COOKIES_BLOCKED,
        taskId     : params.taskId
      })
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  return <div></div>
}

export default CallbackAuth
