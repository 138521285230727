import { ApolloClient } from 'apollo-boost'
// import { createHttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { setContext } from 'apollo-link-context'
import { BatchHttpLink } from 'apollo-link-batch-http'
import { getCredentials } from 'utils/credentials'
import jstz from 'jstz'
import qs from 'query-string'

const timezone = jstz.determine()

const withToken = setContext(() =>  {
  const credentials = getCredentials()

  return {
    headers: {
      authorization: `Bearer ${credentials.accessToken}`,
      ...qs.parse(window.location.search)?.alloweditpostulation ? {
        alloweditpostulation: qs.parse(window.location.search)?.alloweditpostulation
      } : {}
    }
  }
})

const batchLink = new BatchHttpLink({
  batchInterval: 50, // valor por defecto 10
  batchMax     : 10, // valor por defecto 10
  // fetch        : customFetch,
  headers      : {
    timezone: timezone.name()
  },
  uri: `${process.env.REACT_APP_BASE_API_VOD}/graphql`
})

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link : withToken.concat(batchLink)/* .concat(createHttpLink({
    uri: `${process.env.REACT_APP_BASE_API_VOD}/graphql`
  })) */
})

// const client = new ApolloClient({
//   uri: process.env.REACT_APP_BASE_API_VOD
// })

const account = {
  api  : process.env.REACT_APP_ACCOUNTS_API_URL,
  front: process.env.REACT_APP_ACCOUNTS_FRONT_URL
}
const gats = {
  api: process.env.REACT_APP_GATS_API_URL
}

const resti = {
  api: process.env.REACT_APP_RATS_API_URL,
  aws: {
    s3: {
      acl        : process.env.REACT_APP_ACL,
      bucket     : process.env.REACT_APP_BUCKET,
      contentType: process.env.REACT_APP_CONTENT_TYPE,
      region     : process.env.REACT_APP_REGION_AWS
    }
  }
}

const landing = {
  front: process.env.REACT_APP_LANDING_FRONT_URL
}
const base = {
  api  : process.env.REACT_APP_BASE_API_URL,
  // front: process.env.REACT_APP_BASE_FRONT_URL
  front: window.location.origin
}

const jwt = {
  clientId    : process.env.REACT_APP_CLIENT_ID,
  clientSecret: process.env.REACT_APP_CLIENT_SECRET
}

const env = process.env.REACT_APP_ENV

export {
  account,
  gats,
  resti,
  landing,
  base,
  jwt,
  env,
  client
}
