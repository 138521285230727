import { useEffect, useState } from 'react'
import { generatePath, matchPath, useHistory } from 'react-router-dom'
import { useValidAndCreateNewResultTask } from './apollo/hooks'
import qs from 'query-string'
import { useSnackbar } from 'notistack'
import LoginRequired from 'hoc/LoginRequired'

const ActionRequestFormValidator = ({ children }) => {
  const history = useHistory()
  const snackbarController = useSnackbar()

  const [ loading, setLoading ] = useState(true)

  const [ validAndCreateNewResultTask ] = useValidAndCreateNewResultTask()

  useEffect(() => {
    const main = async () => {
      try {
        const match = matchPath(window.location.pathname, {
          path: '/v2/:codeTask/:resultTaskId'
        })

        const qsParams = qs.parse(window.location.search)

        const { data: { validAndCreateNewResultTask: newResultTaskId } } = await validAndCreateNewResultTask({
          variables: {
            actionRequestCode: qsParams.actionRequestCode,
            codeTask         : match?.params?.codeTask,
            resultTaskId     : match?.params?.resultTaskId
          }
        })

        if(match?.params?.resultTaskId && match?.params?.resultTaskId !== newResultTaskId) {
          const newUrl = generatePath('/v2/:codeTask/:resultTaskId', {
            ...match.params,
            resultTaskId: newResultTaskId
          })

          history.replace({
            hash    : '',
            pathname: newUrl,
            search  : window.location.search
          })
        }
      } catch (error) {
        snackbarController.enqueueSnackbar(error.message)
      } finally {
        setLoading(false)
      }
    }

    main()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if(loading) return null

  return children
}

export default LoginRequired(ActionRequestFormValidator)
