import tinycolor from 'tinycolor2'

const intervals = [
  {
    colorMix: 5,
    name    : '10'
  },
  {
    colorMix: 12,
    name    : '50'
  },
  {
    colorMix: 30,
    name    : '100'
  },
  {
    colorMix: 50,
    name    : '200'
  },
  {
    colorMix: 70,
    name    : '300'
  },
  {
    colorMix: 85,
    name    : '400'
  },
  {
    colorMix: 100,
    name    : '500'
  },
  {
    colorMix: 87,
    name    : '600'
  },
  {
    colorMix: 70,
    name    : '700'
  },
  {
    colorMix: 54,
    name    : '800'
  },
  {
    colorMix: 25,
    name    : '900'
  }
]

const multiply = (rgb1, rgb2) => {
  rgb1.b = Math.floor(rgb1.b * rgb2.b / 255)
  rgb1.g = Math.floor(rgb1.g * rgb2.g / 255)
  rgb1.r = Math.floor(rgb1.r * rgb2.r / 255)

  return tinycolor('rgb ' + rgb1.r + ' ' + rgb1.g + ' ' + rgb1.b)
}

export const getGradientPaletteColor = (hex) => {
  var baseLight = tinycolor('#ffffff')
  var baseDark = multiply(tinycolor(hex).toRgb(), tinycolor(hex).toRgb())
  const gradient = intervals.reduce((object, interval) => ({
    ...object,
    [interval.name]: tinycolor(tinycolor.mix(Number(interval.name) > 500 ? baseDark : baseLight, hex, interval.colorMix)).toHexString()
  }), {})

  return {
    ...gradient,
    dark  : gradient['800'],
    main  : gradient['500'],
    medium: gradient['300']
  }
}
