import { useApolloClient } from 'react-apollo'

import GET_URL_KEY from './get-url-key.gql'
import GET_STORAGE_TOKEN from './getStorageToken.gql'

import GET_TASK_BY_ID from './task/get-task-by-id.gql'

import GET_VOD_BY_ID from './vod/get-vod-by-id.gql'
import GET_VOD_BY_EXTERNAL_USER from './vod/get-vod-by-external-user.gql'
import GET_VOD_USER_ID from './vod/get-vod-userId.gql'
import GET_COUNTRIES from './vod/get-countries.gql'

import VERIFY_UPLOAD from './storage/verify-upload.gql'

import GET_CANDIDATE from './candidate/get-candidate.gql'

import GET_FIRST_REMINDER from './vod-reminder/get-first-reminder.gql'

export const useGetCountriesClient = () => {
  const apolloClient = useApolloClient()

  const query = (params) =>
    apolloClient.query({
      query    : GET_COUNTRIES,
      variables: params?.variables
    })

  return [ query ]
}

export const useClientGetVod = () => {
  const client = useApolloClient()

  const query = ({ variables: { vodId } }) => client.query({
    query    : GET_VOD_BY_ID,
    variables: {
      idVod: vodId
    }
  })

  return [ query ]
}

export const useClientGetCandidate = () => {
  const client = useApolloClient()

  const query = ({ variables: { candidateId } }) => client.query({
    query    : GET_CANDIDATE,
    variables: {
      candidateId
    }
  })

  return [ query ]
}

export const useClientGetFirstReminder = () => {
  const client = useApolloClient()

  const query = ({ variables: { vodId } }) => client.query({
    query    : GET_FIRST_REMINDER,
    variables: {
      vodId
    }
  })

  return [ query ]
}

export {
  GET_VOD_BY_ID,
  GET_COUNTRIES,
  GET_URL_KEY,
  GET_TASK_BY_ID,
  VERIFY_UPLOAD,
  GET_VOD_BY_EXTERNAL_USER,
  GET_VOD_USER_ID,
  GET_CANDIDATE,
  GET_FIRST_REMINDER,
  GET_STORAGE_TOKEN
}
