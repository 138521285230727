import { LOCAL_STORAGE_KEYS } from './constants'

export const GrantTokenType = {
  LocalStorage: 'LOCAL_STORAGE',
  QueryString : 'QUERY_STRING'
}

/* export interface Credentials {
  accessToken: string;
  refreshToken: string;
  iduser: string;
  grant: GrantTokenType;
} */

export const getCredentials = () => {
  const lsGetItem = (str) => {
    try {
      return window.localStorage.getItem(str) || localStorage.getItem(str)
    } catch (error) {
      return null
    }
  }

  return {
    accessToken : lsGetItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN),
    iduser      : lsGetItem(LOCAL_STORAGE_KEYS.USER_ID),
    refreshToken: lsGetItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN)
  }
}
