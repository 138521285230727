export const LOCAL_STORAGE_KEYS = {
  ACCESS_TOKEN        : 'accessToken',
  EMAIL               : 'email',
  FIRSTNAME           : 'firstName',
  LASTNAME            : 'lastName',
  MIXPANEL_SESSION    : 'mixpanelSession',
  NUMBER_LOGINS       : 'numberLogins',
  REFRESH_TOKEN       : 'refreshToken',
  SEND_PROFILE_COUNTER: 'send_profile_counter',
  START_SESSION_DATE  : 'start_session_date',
  TOTAL_ERRORS        : 'total_errors',
  USER_ID             : 'iduser'
}

export const ServiceApp = {
  Applying: 'applying'
}
