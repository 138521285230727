import React, { lazy, Suspense } from 'react'
// import { BrowserRouter as Router , Route, Switch} from 'react-router-dom'
import { Redirect, Route, Switch, BrowserRouter as Router, matchPath } from 'react-router-dom'
import styled from 'styled-components'

import CallbackAuth from 'screens/callback-auth'
import Home from 'screens/home'

import Loading from 'components/Loading'
import SupportLiveChat from '../components/SupportLiveChat'
import PrivateRoute from './PrivateRouter'
import ActionRequestFormValidator from 'containers/ActionRequestValidator'

const VodRecording = lazy(() => import(/* webpackChunkName: "VodRecording" */ '../screens/home/vod-recording'))
const VideoVodIntro = lazy(() => import(/* webpackChunkName: "VideoVodIntro" */ '../screens/home/vod-intro/VideoVodIntro'))
const SliderVodIntro = lazy(() => import(/* webpackChunkName: "SliderVodIntro" */ '../screens/home/vod-intro/SliderVodIntro'))
const V2VodPage = lazy(() => import(/* webpackChunkName: "V2VodPage" */ '../screens/v2/video-questionnaire'))
const RecruiterVideoQuestionnairePage = lazy(() => import(/* webpackChunkName: "RecruiterVideoQuestionnairePage" */ '../screens/update/video-questionnaire'))
class Routes extends React.Component {
  handleResize = () => {
    const vh = document.documentElement.clientHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  }
  componentDidMount = () => {
    const vh = document.documentElement.clientHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
    window.addEventListener('resize', this.handleResize, false)
  }
  componentWillUnmount = () => {
    window.removeEventListener('resize', this.handleResize, false)
  }

  render() {
    return (
      <Router>
        <Switch>
          <Redirect
            exact
            from='/'
            to='/no-match' />
          <Redirect
            exact
            from='/vod/:idVod'
            to='/vod/:idVod/list/vertical' />
          <Redirect
            exact
            from='/vodp/:idVod'
            to='/vodp/:idVod/list/vertical' />
          <Route component={CallbackAuth} exact path='/callback' />
          <Route component={NoPermission} exact path='/no-permission' />
          <Route component={VodNoExist} exact path='/no-exist' />
          <Route path='/v2/video-questionnaire/:idVod'>
            <ActionRequestFormValidator>
              <Home>
                <Switch>
                  <Route
                    exact path='/v2/video-questionnaire/:idVod'
                    render={(props) => (
                      <Suspense fallback={<Loading />}>
                        <V2VodPage {...props} />
                      </Suspense>
                    )} />
                  <Route
                    exact path='/v2/video-questionnaire/:idVod/recording/:idQuestion'
                    render={(props) => (
                      <Suspense fallback={<Loading />}>
                        <VodRecording {...props} />
                      </Suspense>
                    )} />
                  <Route
                    exact path='/v2/video-questionnaire/:idVod/introduction/slider'
                    render={(props) => (
                      <Suspense fallback={<Loading />}>
                        <SliderVodIntro {...props} />
                      </Suspense>
                    )} />
                  <Route
                    exact path='/v2/video-questionnaire/:idVod/introduction'
                    render={(props) => (
                      <Suspense fallback={<Loading />}>
                        <VideoVodIntro {...props} />
                      </Suspense>
                    )} />
                </Switch>
              </Home>
            </ActionRequestFormValidator>
          </Route>
          <Route
            exact path='/update/video-questionnaire/:idVod'
            render={(props) => (
              <PrivateRoute>
                <Suspense fallback={<Loading />}>
                  <RecruiterVideoQuestionnairePage {...props} />
                </Suspense>
              </PrivateRoute>
            )} />
          <Route component={NoMatch} exact />
        </Switch>
        {!matchPath(window.location.pathname, {
          exact: true,
          path : '/update/video-questionnaire/:idVod'
        }) && <SupportLiveChat />}
      </Router>
    )
  }
}

const PageNot = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
`

const ImageBee = styled.img`
  display: block;
  width: 144px;
`

const PageNotText = styled.p`
  text-align: center;
  font-size: 18px;
  margin-top: 12px;
  font-weight: 500;
  color: #8C8C8C;
`
const NoMatch = () => (
  <PageNot>
    <ImageBee alt='' src='https://cdn.krowdy.com/media/images/krowdy-empty.svg' />
    <PageNotText>Página no encontrada</PageNotText>
  </PageNot>
)

const NoPermission = () => (
  <PageNot>
    <ImageBee alt='' src='https://cdn.krowdy.com/media/images/krowdy-empty.svg' />
    <PageNotText>Sin permisos</PageNotText>
  </PageNot>
)

const VodNoExist = () => (
  <PageNot>
    <ImageBee alt='' src='https://cdn.krowdy.com/media/images/krowdy-empty.svg' />
    <PageNotText>No existe video cuestionario</PageNotText>
  </PageNot>
)

export default Routes
