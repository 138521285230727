/* eslint-disable no-restricted-syntax */
import React from 'react'
import { generatePath, matchPath, withRouter } from 'react-router'

import VodContext from 'stores/VodContext'

import * as compose from 'lodash.flowright'
import { withApollo } from 'react-apollo'

import { GET_VOD_BY_ID } from '../../functions/graphql/queries'

class Home extends React.Component {
  state = {
    firstTest      : false,
    settingsVodCard: {
      beforeChange: (_, next) => {
        const { vod } = this.state
        const nextIndex = (next) % vod.questions.length
        const idNextQuestion = vod.questions[nextIndex]._id

        this.props.history.push(`${idNextQuestion}`)
      },
      centerMode  : false,
      infinite    : false,
      initialSlide: 0,
      loading     : true,
      responsive  : [
        {
          breakpoint: 1024,
          settings  : {
            arrows        : false,
            dots          : true,
            slidesToScroll: 3,
            slidesToShow  : 3
          }
        },
        {
          breakpoint: 768,
          settings  : {
            arrows        : false,
            initialSlide  : 3,
            slidesToScroll: 2,
            slidesToShow  : 3
          }
        },
        {
          breakpoint: 600,
          settings  : {
            arrows        : false,
            centerMode    : true,
            initialSlide  : 3,
            slidesToScroll: 3,
            slidesToShow  : 3
          }
        },
        {
          breakpoint: 480,
          settings  : {
            arrows        : false,
            centerMode    : true,
            centerPadding : '40px',
            slidesToScroll: 1,
            slidesToShow  : 1
          }
        }
      ],
      slidesToScroll: 3,
      slidesToShow  : 4
    },
    vod: null
  }
  componentDidMount = async () => {
    const matchPathContentForm = matchPath(this.props.location.pathname, {
      path: '/:pathContentForm/:idVod'
    })

    const matchV2 = matchPath(this.props.location.pathname, {
      path: '/v2/video-questionnaire/:idVod'
    })

    const { match, client, history } = this.props

    try {
      const { data } = await client.query({
        query    : GET_VOD_BY_ID,
        variables: {
          idVod: match.params.idVod
        }
      })

      this.setState({
        vod: data.getVodById
      }, () => {
        if(!data.getVodById.tutorialShowed && !matchV2) history.push({
          pathname: generatePath(`${match.path}/intro` , {
            idVod          : match.params.idVod,
            pathContentForm: matchV2?.params?.pathContentForm ?? matchPathContentForm?.params?.pathContentForm
          })
        })
      })
    } catch (error) {
      if(error.message.includes('Cast to ObjectId failed for value') && error.message.includes('at path "_id" for model "Vod"'))
        history.push('/no-exist')

      if(error.message.includes('El vod no le pertenece'))
        history.push('/no-permission')
    }
  }
  render() {
    const { vod, settingsVodCard, firstTest } = this.state
    const {
      pushSourceToQuestion,
      getLastSourceOfQuestion,
      getQuestion,
      getIndexByQuestion,
      updateVod,
      isFinished,
      setFirstTest
    } = this

    const matchPathContentForm = matchPath(this.props.location.pathname, {
      path: '/:pathContentForm/:idVod'
    })

    const matchV2 = matchPath(this.props.location.pathname, {
      path: '/v2/video-questionnaire/:idVod'
    })

    const { children } = this.props

    if(!vod) return <></>

    return (
      <VodContext.Provider
        value={{
          firstTest,
          getIndexByQuestion,
          getLastSourceOfQuestion,
          getQuestion,
          isFinished,
          pathContentForm: matchV2?.params?.pathContentForm ?? matchPathContentForm?.params?.pathContentForm,
          pushSourceToQuestion,
          setFirstTest,
          settingsVodCard,
          updateVod,
          vod
        }}>
        {children}
      </VodContext.Provider>
    )
  }
  setFirstTest = (value) => this.setState({ firstTest: value })
  isFinished = () => {
    const { vod } = this.state
    let finished = true
    vod.questions.forEach(question => {
      if(!question.sources.length) { finished = false }
      else {
        const lastSource = this.getLastSourceOfQuestion(question._id)
        if(lastSource && !lastSource.upload)
          finished = false
      }
    })

    return finished
  }
  updateVod = (vod, callback = () => { }) => {
    this.setState({
      vod: vod
    }, () => {
      callback()
    })
  }
  getLastSourceOfQuestion = (idQuestion) => {
    const { vod } = this.state
    const [ questionFound ] = vod.questions.filter((question) => question._id === idQuestion)
    const { sources } = questionFound

    const sourcesEnable = sources.filter((s) => s.enable)

    return sourcesEnable[sourcesEnable.length - 1]
  }
  getQuestion = (idQuestion) => {
    const { vod } = this.state

    return vod.questions.filter((question) => question._id === idQuestion)[0]
  }
  getIndexByQuestion = (idQuestion) => {
    const { vod } = this.state
    let indexQuestion
    vod.questions.forEach((question, index) => {
      if(question._id === idQuestion)
        indexQuestion = index
    })

    return indexQuestion
  }

  pushSourceToQuestion = (idQuestion, source) => {
    const { vod } = this.state
    const newQuestions = vod.questions.map((question) => {
      const { _id, version, sources } = question
      if(_id === idQuestion) {
        sources[version] = source

        return {
          ...question,
          sources
        }
      }

      return {
        ...question
      }
    })
    this.setState({
      vod: {
        ...vod,
        questions: newQuestions
      }
    })
  }
}

export default compose(
  withApollo,
  withRouter
)(Home)
