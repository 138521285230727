import React, { useEffect, useMemo, useState } from 'react'
import { ApolloProvider } from 'react-apollo'
import ReactDOM from 'react-dom'
import 'antd/dist/antd.css'
import 'react-circular-progressbar/dist/styles.css'
import { ThemeProvider } from 'styled-components'
import Raven from 'raven-js'
import { ThemeProvider as KThemeProvider, createTheme, krowdyTheme } from '@krowdy/kds-core'
import { SnackbarProvider } from 'notistack'
import queryString from 'query-string'
import produce from 'immer'

import './index.css'
import Routes from './routes'
import * as serviceWorker from './serviceWorker'
import { client } from './config'
import { getGradientPaletteColor } from './utils/colors'

// import StreamVod from './helpers/StreamVod'
const theme = {
  font_family: [ 'Roboto', 'sans-serif' ],
  header     : {
    height: 52
  }
}

if(process.env.REACT_APP_ENV === 'prod')
  Raven.config('https://431a22513ac84f118d78f1abb454eb1f@sentry.krowdy.com/16')
    .install()

const App = () => {
  const [ colors, setColors ] = useState({
    custom   : krowdyTheme.palette.custom.main,
    primary  : krowdyTheme.palette.primary.main,
    secondary: krowdyTheme.palette.secondary.main
  })

  useEffect(() => {
    const queryParams = queryString.parse(window.location.search)
    setColors({
      custom   : queryParams.customColor ?? krowdyTheme.palette.custom.main,
      primary  : queryParams.primaryColor ?? krowdyTheme.palette.primary.main,
      secondary: queryParams.secondaryColor ?? krowdyTheme.palette.secondary.main
    })
  }, [])

  const themeM = useMemo(() => {
    const theme = produce(krowdyTheme, (theme) => {
      for (const field of [ 'custom', 'primary', 'secondary' ])
        if(colors[field])
          theme['palette'][field] = {
            ...krowdyTheme.palette[field],
            ...getGradientPaletteColor(colors[field])
          }
    })

    return createTheme(theme)
  },[ colors ])

  return (
    <ApolloProvider
      client={client}>
      <KThemeProvider theme={themeM}>
        <ThemeProvider theme={theme}>
          <SnackbarProvider
            anchorOrigin={{
              horizontal: 'center',
              vertical  : 'top'
            }}
            autoHideDuration={3000}
            maxSnack={1}>
            <Routes />
          </SnackbarProvider>
        </ThemeProvider>
      </KThemeProvider>
    </ApolloProvider>
  )
}

window.onload = () => {
  // await StreamVod.pollyfill()
  ReactDOM.render(
    <App />
    , document.getElementById('root'))

  serviceWorker.register()
}

