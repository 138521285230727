import React, { useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet'
import queryString from 'query-string'
import { makeStyles } from '@krowdy/kds-core'
import { matchPath, useLocation } from 'react-router'
import { getUser } from '../../functions/rest/auth'

const Style = () => {
  useStyles(window.location.pathname)

  return (
    <>
    </>
  )
}

const SupportLiveChat = () => {
  const location = useLocation()
  const [ loading, setLoading ] = useState(true)
  const [ user, setUser ] = useState('')

  useEffect(() => {
    const main = async () => {
      try {
        const { data: { user } } = await getUser()
        setUser(user)
      } catch (error) {
      } finally {
        setLoading(false)
      }
    }
    main()
  }, [])

  const show = useMemo(() => matchPath(location.pathname, {
    path: '/v2/video-questionnaire/:idVod'
  }), [ location.pathname ])

  if(loading) return null

  return (
    <>
      <Helmet>
        <script
          src={
            `https://mylivechat.com/chatinline.aspx?${queryString.stringify({
              HCCID         : 67760239,
              InPagePosition: 'bottomright',
              InPageTemplate: 1
            })}`
          }
          type='text/javascript' />
        <script>
          {`
          function MyLiveChat_OnInit() {
            MyLiveChat_SetUserName('${user?.firstName} ${user?.lastName}')
            MyLiveChat_SetEmail('${user?.email}')
            MyLiveChat_SetDepartment('Default')
            MyLiveChat_SetQuestion('test question')
            MyLiveChat_SetContextData('This is VIP')
            MyLiveChat_SetProductName('')
            MyLiveChat_SetProductKey('${window.location.pathname}')
          }
        `}
        </script>
      </Helmet>
      {Boolean(show) && <Style />}
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  '@global': {
    '.mylivechat_closediv *': {
      display: 'none !important'
    },

    '.mylivechat_collapsed': {
      visibility: 'hidden !important'
    },

    '.mylivechat_inline': {
      bottom: '0 !important',
      right : '0 !important'
    },
    [theme.breakpoints.up('md')]: {
      '.mylivechat_closediv': {
        height: '37px !important'
      },
      '.mylivechat_expanded': {
        right: '83px !important'
      }
    },
    '.mylivechat_inline.mylivechat-mobile-docked': {
      padding: '0 !important'
    }
  }
}), { name: 'SupportLiveChat' })

export default SupportLiveChat
