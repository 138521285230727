import React from 'react'
import RecordingContext from './RecordingContext'

const VodContext = React.createContext({
  name: 'vodContext'
})
export {
  RecordingContext
}
export default VodContext
