import axios from 'axios'
import { getCredentials } from 'utils/credentials'
import { axiosAccountConfig } from './utils'

const authenticate = async () => {
  try {
    const isAuthenticate = await axios.get('api/authenticate', axiosAccountConfig)

    return isAuthenticate
  } catch (error) {
    if(error && error.response && error.response.status === 401) {
      // eslint-disable-next-line no-restricted-syntax
      console.log(`[Krowdy] - ONETAP : [${error.response.data.message}]`)

      return { data: { success: false } }
    }

    return { data: { success: false } }
  }
}

const getUser = async (token) => {
  try {
    const infoUser = await  axios.get(`api/getInfoUser?id=${token || getCredentials().iduser}`, axiosAccountConfig)

    return infoUser
  } catch (error) {
    if(error && error.response && error.response.status === 401) {
      // eslint-disable-next-line no-restricted-syntax
      console.log(`[Krowdy] - ONETAP : [${error.response.data.message}]`)

      return { data: { success: false } }
    }

    return { data: { success: false } }
  }
}

export {
  authenticate,
  getUser
}
