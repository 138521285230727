import { isParsable } from './check'
import { mongoObjectId } from './generator'

export const ProblemTypeEnum = {
  BROWSER_NOT_SUPPORTED: 'BROWSER_NOT_SUPPORTED',
  COOKIES_BLOCKED      : 'COOKIES_BLOCKED'
}

export const Action = {
  ACK                  : 'ACK',
  // usado para subida archivos
  FINISH               : 'FINISH',
  GET_PROFILE_DATA     : 'GET_PROFILE_DATA',
  HIDE_FOOTER          : 'HIDE_FOOTER',
  ON_CHANGE_FULL_SCREEN: 'ON_CHANGE_FULL_SCREEN',
  ON_CHANGE_LOADING    : 'ON_CHANGE_LOADING',
  ON_CLICK_SUPPORT     : 'ON_CLICK_SUPPORT',
  ON_LOAD              : 'ON_LOAD',
  ON_PROBLEM           : 'ON_PROBLEM',
  ON_VALID_SECTION     : 'ON_VALID_SECTION',
  START_TASK           : 'START_TASK',
  UPDATE_PROFILE_DATA  : 'UPDATE_PROFILE_DATA',
  VALID_SECTION        : 'VALID_SECTION',
  VERIFY_ALREADY_CLOSE : 'VERIFY_ALREADY_CLOSE'
}

export const inIframe = () => {
  try {
    return window.self !== window.top
  } catch (e) {
    return true
  }
}

const PostMessageProtocol = {
  TcpIp: 'TcpIp',
  Udp  : 'Udp'
}

const setTimeOutIds = []

export const postMessage = (action, taskId, payload, protocol = PostMessageProtocol.TcpIp) => {
  const postMessageId = mongoObjectId()

  window.parent.postMessage(
    JSON.stringify({
      payload,
      taskId,
      type: action
    }),
    '*'
  )

  if(protocol === PostMessageProtocol.Udp) return Promise.resolve()

  return new Promise((resolve, reject) => {
    let hasAnError = true
    const listener = window.addEventListener('message', (event) => {
      // validar de q estas peticiones solo provengan de urls definidas dentro del categoryTask
      if(typeof event.data === 'string' && isParsable(event.data)) {
        const { type, payload, postMessageId: postMessageIdResp } = JSON.parse(String(event.data))

        if(type === 'ACK' && postMessageIdResp !== postMessageId) return

        if(type === action) {
          hasAnError = false

          resolve(payload)
        }

        if(type === action || type === 'ACK') {
          window.removeEventListener('message', listener)
          const timeOut = setTimeOutIds.find((element) => element.postMessageId === postMessageId)

          clearTimeout(timeOut.timeOutId)
          setTimeOutIds.filter((element) => element.postMessageId !== postMessageId)
        }
      }
    })
    const id = setTimeout(() => {
      window.removeEventListener('message', listener)
      if(hasAnError)
        reject(new Error(`PostMessage Timeout 5000 ${action}`))
      setTimeOutIds.filter((element) => element.postMessageId !== postMessageId)
    }, 5000)

    setTimeOutIds.push({
      postMessageId,
      timeOutId: id
    })
  })
}

const changeFullScreen = async ({ checked, taskId }) => {
  if(inIframe())
    await postMessage(Action.ON_CHANGE_FULL_SCREEN, taskId, { checked })

  // window.parent.postMessage(
  //   JSON.stringify({
  //     payload: {
  //       checked
  //     },
  //     taskId,
  //     type: Action.ON_CHANGE_FULL_SCREEN
  //   }),
  //   '*'
  // )
}

const changeHideFooter = async ({ isHidden, taskId }) => {
  if(inIframe())
    await postMessage(Action.HIDE_FOOTER, taskId, { isHidden })
}

const getProfileData = async ({ taskId }) => {
  try {
    if(inIframe()) {
      const payload = await postMessage(Action.GET_PROFILE_DATA, taskId, {})

      return payload
    }
  } catch (error) {
    // eslint-disable-next-line no-restricted-syntax
    console.log(error)
  }
}

const updateProfileData = async ({ taskId, payload }) => {
  try {
    if(inIframe())
      await postMessage(Action.UPDATE_PROFILE_DATA, taskId, { ...payload })
  } catch (error) {
    // eslint-disable-next-line no-restricted-syntax
    console.log(error)
  }
}

const onLoad = ({ taskId, currentSectionIndex, sections, isIntroduction, hasProblems }) => {
  if(inIframe())
    window.parent.postMessage(
      JSON.stringify({
        payload: {
          currentSectionIndex,
          hasProblems,
          isIntroduction,
          sections
        },
        taskId,
        type: Action.ON_LOAD
      }),
      '*'
    )
}

const validSection = ({ taskId, validation }) => {
  if(inIframe())
    window.parent.postMessage(
      JSON.stringify({
        payload: {
          validation
        },
        taskId,
        type: Action.VALID_SECTION
      }),
      '*'
    )
}

const finish = ({ taskId, success = true, message }) => {
  if(inIframe())
    window.parent.postMessage(
      JSON.stringify({
        payload: {
          message,
          success
        },
        taskId,
        type: Action.FINISH
      }),
      '*'
    )
}

const ack = ({ taskId, postMessageId }) => {
  if(inIframe())
    window.parent.postMessage(
      JSON.stringify({
        payload: {},
        postMessageId,
        taskId,
        type   : Action.ACK
      }),
      '*'
    )
}

const onClickSupportMyLiveChat = ({ taskId }) => {
  if(inIframe())
    window.parent.postMessage(
      JSON.stringify({
        payload: {},
        taskId,
        type   : Action.ON_CLICK_SUPPORT
      }),
      '*'
    )
}

const onChangeLoading = ({ taskId, loading }) => {
  if(inIframe())
    window.parent.postMessage(
      JSON.stringify({
        payload: {
          loading
        },
        taskId,
        type: Action.ON_CHANGE_LOADING
      }),
      '*'
    )
}

const verifyAlreadyToClose = ({ ok, taskId }) => {
  if(inIframe())
    window.parent.postMessage(
      JSON.stringify({
        payload: {
          ok
        },
        taskId,
        type: Action.VERIFY_ALREADY_CLOSE
      }),
      '*'
    )
}

const onProblems = ({ hasProblems, problemType, taskId }) => {
  if(inIframe())
    window.top.postMessage(
      JSON.stringify({
        payload: {
          hasProblems,
          problemType
        },
        taskId,
        type: Action.ON_PROBLEM
      }),
      '*'
    )
}

const onValidSection = ({ validation, taskId }) => {
  if(inIframe())
    window.top.postMessage(
      JSON.stringify({
        payload: {
          validation
        },
        taskId,
        type: Action.ON_VALID_SECTION
      }),
      '*'
    )
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ack,
  changeFullScreen,
  changeHideFooter,
  finish,
  getProfileData,
  onChangeLoading,
  onClickSupportMyLiveChat,
  onLoad,
  onProblems,
  onValidSection,
  updateProfileData,
  validSection,
  verifyAlreadyToClose
}
