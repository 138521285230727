import React from 'react'
import queryString from 'query-string'
import { account, base } from '../config'
import { authenticate, getUser } from '../functions/rest/auth'
import { getSearchParams } from 'utils/searchParams'
// import Loading from '../screens/Loading'

export const verifyAuth = async () => {
  try {
    /* OBTENER ACCESSTOKEN ACTUAL DE ACCOUNTS */
    const resAuthenticate = await authenticate()

    if(resAuthenticate.data.success) {
      /* EL USUARIO EXISTE EN BACKEND */
      const resGetInfo = await getUser()
      if(!resGetInfo.data.success) window.location.href = `${base.front}/no-match`
      window.localStorage.setItem('iduser', resGetInfo.data.user._id)
    } else {
      const search = queryString.stringify({
        timestamp  : new Date().getTime(),
        urlCallback: `${base.front}/callback`,
        urlRedirect: window.location.href
      })
      window.location.href =  `${account.front}/login?${search}`
    }
  } catch (err) {
    // eslint-disable-next-line no-restricted-syntax
    console.log('Error al authenticar')
  }
}

const LoginRequired = (Component) => class Wrapper extends React.Component {
  state = {
    loading: true
  }
  componentDidMount = async () => {
    try {
      const qsParams = getSearchParams()

      if(qsParams.alloweditpostulation) return

      await verifyAuth()
    } catch (error) {
    } finally {
      this.setState({
        loading: false
      })
    }
  }
  render() {
    if(this.state.loading) return <div />// <Loading />

    return (
      <Component {...this.props} />
    )
  }
}

export default LoginRequired
